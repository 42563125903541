<template>
  <div>
     <transition name="fade">
       <div class="modal" v-show="isOpen" >
        <transition name="drop-in">
          <div class="modal-inner" v-show="isOpen" ref="target" >
            <div class="modal-content">
            <h1 class="title">{{title}}</h1> 
            <div class="text-start mb-10">
              <div>
                <p class="font-bold text-lg">{{ $t('programName') }}</p>
                <p class="font-medium text-md">{{ programDetail.programName }}</p>
              </div>           
              <div class="grid grid-cols-2 gap-4 mt-5">
                <div>
                  <p class="font-bold text-lg">{{ $t('startDate') }}</p>
                  <p class="font-medium text-md">{{ props.startDate.split('T')[0] }}</p>
                </div>
                <div>
                  <p class="font-bold text-lg">{{ $t('endDate') }}</p>
                  <p class="font-medium text-md">{{ props.endDate.split('T')[0] }}</p>
                </div>
              </div>      
              <div class="mt-5 ">
                <p class="font-bold text-lg">{{ $t('startTime') }}</p>
                <div v-if="planningStore.getProgramDetailLoading" class="mt-2 max-w-sm animate-pulse">
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </div>
                <table class="w-full mt-3">
                  <thead>
                    <tr>
                      <th class="text-center">Number</th>
                      <th class="text-center">Hour</th>
                      <th class="text-center">Minute</th>
                      <th class="text-center">Cycle</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!planningStore.getProgramDetailLoading" v-for="(data, index) in programDetail.startTime">
                      <td class="text-center">{{ index + 1}}</td>
                      <td class="text-center">{{ data.hour }}</td>
                      <td class="text-center">{{ data.minute }}</td>
                      <td class="text-center">{{ data.cycle }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>                         
              <div class="mt-5">
                <p class="font-bold text-lg">{{ $t('stationActive') }}</p>
                <div v-if="planningStore.getProgramDetailLoading" class="mt-2 max-w-sm animate-pulse">
                  <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                </div>
                <p v-if="programDetail.isStationEmpty && !planningStore.getProgramDetailLoading">No Station</p>
                <table class="w-full mt-3">
                  <thead>
                    <tr v-if="programDetail.mode === 1">
                      <th>Stations</th>
                      <th>Minute</th>
                      <th>Second</th>
                    </tr>
                  </thead>
                  <thead>
                    <tr v-if="programDetail.mode === 2">
                      <th>Stations</th>
                      <th>Hour</th>
                      <th>Minute</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="!planningStore.getProgramDetailLoading" v-for="station in programDetail.stations">
                      <td class="text-center">{{ station.groupName }}</td>
                      <td class="text-center">{{ station.timeDigit1 }}</td>
                      <td class="text-center">{{ station.timeDigit2 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>                         
            </div>   
          </div>
        </div>
      </transition>
    </div>
  </transition>
  </div>
</template>

  
<script setup>
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { onClickOutside } from '@vueuse/core'
import { useI18n } from 'vue-i18n';
import { usePlanningStore } from '@/stores/planning/PlanningStore';
const { t } = useI18n();

  const props = defineProps({
      isOpen: Boolean,
      title: String,
      program_code: Number,
      deviceCode: String,
      startDate: String,
      endDate: String,
  })

  const cancelLabel = ref(t('cancel'))
  const registerLabel = ref(t('save'))
  const regButtonClick = ref(0)
  const cancelButtonClick = ref(0)
  const target = ref(null)
  const planningStore = usePlanningStore()
  const { programDetail } = storeToRefs(usePlanningStore())

  const emits = defineEmits(['close'])

  watch(() => props.isOpen, async () => {
    if(props.isOpen === true) {
      await planningStore.getProgramDetail(props.program_code, {deviceCode: props.deviceCode})
    }
  })


  onClickOutside(target, () => {
    cancelButtonClick.value = 0
    regButtonClick.value = 0
    registerLabel.value = t('save')
    cancelLabel.value = t('cancel')
    if (props.isOpen) {
      emits('close')
    }
  })




</script>
  
  <style scoped>

  .title {
    @apply 
      text-left border-b-[1px] pb-[18px] 
      sm:text-2xl text-base font-normal text-[#353535] mb-6
  }
  

  .message-wrapper {
    @apply flex items-center gap-2
  }
  
  .form-wrapper{
    @apply flex flex-col gap-6 text-[#353535]
  }

  .field-wrapper {
    @apply flex flex-col gap-2 text-left
  }
  
.modal {
  @apply 
    bg-[#ABADAF]/20 backdrop-blur-sm
    w-full h-full
    fixed top-0 left-0 px-8 pt-10 pb-4
    overflow-x-hidden overflow-y-auto z-40
    flex 
}

.modal-inner {
  background: white;
  @apply 
  rounded-[40px] border-2 border-[#005CB4] max-w-[550px] w-full h-fit p-10 m-auto
}

/* .modal-content {
  @apply 
    relative w-[600px] p-[40px] bg-white gap-[30px] rounded-xl
} */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.select-option {
  @apply  px-3 py-2
          rounded-lg border bg-white
          text-[14px]
} 

  
  </style>