<template>
  <div>
  <alert 
  :message="status.message"
    :modalActive="modalActive"
    :isError="status.isError"
     @close="closeNotification" />
     <transition name="fade">
       <div class="modal" v-show="isOpen" >
        <transition name="drop-in">
          <div class="modal-inner" v-show="isOpen" ref="target" >
            <div class="modal-content">
            <h1 class="title">{{title}}</h1> 
            <div class="mb-10">
              <div>
                <p v-if="dataProgram.length === 0" class="text-center my-4 font-bold text-lg">No Programs</p>
                <div class="text-start p-4 mb-3 rounded-lg bg-[#f3f7f8]" v-for="(program, index) in dataProgram" :key="index">
                  <div class="grid grid-cols-2 gap-2">
                    <div class="text-center">
                      <p class="mb-2 text-[#aaaaaa]">Programs</p>
                      <select class="dropdown" v-model="program.selected" :disabled="program.disabled">
                        <option disabled value="0">{{ $t('selectProgram') }}</option>
                        <option 
                          v-for="program in availablePrograms" 
                          :key="program" 
                          :value="program" 
                          :disabled="isProgramDisabled(program, index)"
                        >
                          Program {{ program }}
                        </option>                      
                      </select>
                    </div>
                    <!-- <div class="text-center">
                      <p class="mb-3.5 text-[#aaaaaa]">State</p>
                      <label class="inline-flex items-center me-5 cursor-pointer"  style="align-self: center">
                        <input type="checkbox" class="sr-only peer outline-none" :checked="program.checked" @change="toggleActive(program)" :disabled="program.disabled">
                        <div class="relative w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                        <span class="ms-3 text-sm font-medium text-gray dark:text-gray">{{ program.checked === false ? 'Deactive' : 'Active' }}</span>
                      </label>
                    </div> -->
                    <div class="w-full h-full flex items-center justify-center">
                      <div class="text-center" style="align-self: center;">
                        <button v-if="program.disabled === false" type="button" class="focus:outline-none mt-3 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-3xl text-sm px-10 py-2.5  dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800" @click="onSubmit(program.selected, program.checked)" :disabled="program.selected === 0">{{ $t('submit') }}</button>
                        <button v-if="program.disabled === true" type="button" class="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-3xl text-sm px-10 py-2.5  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900" @click="deleteSchedule(program.id, program.selected)">{{ $t('delete') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button type="button" class="w-full py-3.5 me-2 mt-4 border border-gray-300 rounded-2xl text-lg hover:bg-gray-100 " style="align-self: center" @click="addPrograms()">
                {{ $t('addProgram') }}
              </button>
            </div>   
          </div>
        </div>
      </transition>
    </div>
  </transition>
  </div>
</template>

  
<script setup>
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useScheduleStore } from '@/stores/ScheduleStore';
import { onClickOutside } from '@vueuse/core'
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

  const props = defineProps({
      isOpen: Boolean,
      title: String,
      scheduleAt: String,
      endDate: String,
      deviceCode: String,
  })

  const modalActive = ref(false)
  const cancelLabel = ref(t('cancel'))
  const registerLabel = ref(t('save'))
  const scheduleStore = useScheduleStore()
  const { status, scheduleSpecificList } = storeToRefs(useScheduleStore())
  const regButtonClick = ref(0)
  const cancelButtonClick = ref(0)
  const target = ref(null)

  const dataProgram = ref([])
  const availablePrograms = ref(Array.from({ length: 30 }, (_, i) => i + 1))

  const emits = defineEmits(['close'])

  async function onSubmit(program, value){
    const scheduleDate = new Date(props.scheduleAt)
    scheduleDate.setHours(scheduleDate.getHours() - 1)

    const payload = {
      device_code: props.deviceCode,
      program: program,
      scheduleAt: scheduleDate.toISOString()
    }

    await scheduleStore.createSchedule(payload)
    if(status.isError !== true){
      const fromScheduleDate = new Date(props.scheduleAt)
      fromScheduleDate.setHours(fromScheduleDate.getHours() - 1)
      const fromFilterIso = fromScheduleDate.toISOString()

      const toScheduleDate = new Date(props.endDate)
      toScheduleDate.setHours(toScheduleDate.getHours() - 1)
      const toFilterIso = toScheduleDate.toISOString()

      await scheduleStore.getSpecificSchedule(fromFilterIso, toFilterIso, props.deviceCode)
      modalActive.value = true
      const programToUpdate = dataProgram.value.find(item => item.selected === program);
      programToUpdate.disabled = true;
      setTimeout(closeNotification, 3000)
    }
  }

  async function deleteSchedule(id, program) {
    await scheduleStore.deleteSchedule(id)
    if(status.isError !== true){
      const fromScheduleDate = new Date(props.scheduleAt)
      fromScheduleDate.setHours(fromScheduleDate.getHours() - 1)
      const fromFilterIso = fromScheduleDate.toISOString()

      const toScheduleDate = new Date(props.endDate)
      toScheduleDate.setHours(toScheduleDate.getHours() - 1)
      const toFilterIso = toScheduleDate.toISOString()

      await scheduleStore.getSpecificSchedule(fromFilterIso, toFilterIso,  props.deviceCode)
      modalActive.value = true

      const deleteData = dataProgram.value.findIndex(item => item.selected === program);
      if (deleteData !== -1) {
        dataProgram.value.splice(deleteData, 1); // Hapus item dari array asli
      }

      setTimeout(closeNotification, 3000)
    }
  } 

  const closeNotification = () => {
    modalActive.value = false
  }

  onClickOutside(target, async () => {
    cancelButtonClick.value = 0
    regButtonClick.value = 0
    registerLabel.value = t('save')
    cancelLabel.value = t('cancel')
    if (props.isOpen) {
      emits('close')
    }
  })
  
  const toggleActive = (program) => {
    program.checked = !program.checked;
    program.value = program.checked ? 'active' : 'deactive';
  };

  function getTrailingNumber(str) {
    const match = str.match(/\d+$/); 
    return match ? parseInt(match[0], 10) : null; 
  }

  function addPrograms() {
    dataProgram.value.push({
      selected: 0,
      disabled: false
    })
  }

  watch(() => props.isOpen, async () => {
    dataProgram.value = []    
    const fromScheduleDate = new Date(props.scheduleAt)
    fromScheduleDate.setHours(fromScheduleDate.getHours() - 1)
    const fromFilterIso = fromScheduleDate.toISOString()

    const toScheduleDate = new Date(props.endDate)
    toScheduleDate.setHours(toScheduleDate.getHours() - 1)
    const toFilterIso = toScheduleDate.toISOString()

    await scheduleStore.getSpecificSchedule(fromFilterIso, toFilterIso,  props.deviceCode)
    scheduleSpecificList.value.map((item) => {
      dataProgram.value.push({
        id: item.id,
        selected: getTrailingNumber(item.payload_key),
        disabled: true
      })
    })
  })

  function isProgramDisabled(program, currentIndex) {
    return dataProgram.value.some((dropdown, index) => {
      return index !== currentIndex && dropdown.selected === program;
    });
  }


</script>
  
  <style scoped>

  .title {
    @apply 
      text-left border-b-[1px] pb-[18px] 
      sm:text-2xl text-base font-normal text-[#353535] mb-6
  }
  

  .message-wrapper {
    @apply flex items-center gap-2
  }
  
  .form-wrapper{
    @apply flex flex-col gap-6 text-[#353535]
  }

  .field-wrapper {
    @apply flex flex-col gap-2 text-left
  }
  
.modal {
  @apply 
    bg-[#ABADAF]/20 backdrop-blur-sm
    w-full h-full
    fixed top-0 left-0 px-8 pt-10 pb-4
    overflow-x-hidden overflow-y-auto z-40
    flex 
}

.dropdown {
  @apply pl-2 rounded w-20 sm:w-60 py-2 sm:py-3 cursor-pointer outline-none border border-gray-300
}

.modal-inner {
  background: white;
  @apply 
  rounded-[40px] border-2 border-[#005CB4] max-w-[700px] w-full h-fit p-10 m-auto
}
/* .modal-content {
  @apply 
    relative w-[600px] p-[40px] bg-white gap-[30px] rounded-xl
} */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.select-option {
  @apply  px-3 py-2
          rounded-lg border bg-white
          text-[14px]
} 
  </style>