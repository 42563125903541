<template>
  <div>
    <transition name="fade">
      <div class="modal" v-show="isOpen">
        <transition name="drop-in">
          <div class="modal-inner" v-show="isOpen" ref="target">
            <div class="modal-content">
              <h1 class="title">{{ title }}</h1>

            </div>

            <div class="prog-wrapper">
              <div class="toggle-wrapper" v-for="prog, index in progs" :key="prog">
                <span class="font-semibold">prog{{ index + 1 }}</span>
                <label class="inline-flex relative items-center cursor-pointer">
                  <input type="checkbox" @click="onToggle(index + 1)" v-model="prog['prog' + (index + 1)]"
                    class="sr-only peer">
                  <div
                    class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                  </div>
                </label>
              </div>
            </div>
            <div class="button-wrapper">
              <BaseButton type="button" class="filled__red mt-6"  :label="$t('exit')" @click="emits('close')"/>
            </div>

          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>


<script setup>
import { onBeforeUpdate, onMounted, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import BaseButton from '@/components/button/BaseButton.vue'
import { onClickOutside } from '@vueuse/core'
import MyButton from '@/components/button/BaseButton.vue'
import { useDataStore } from '@/stores/DataStore';
import { useDevicesStore } from '@/stores/DevicesStore'
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const dataStore = useDataStore()
const { postControlIsLoading } = storeToRefs(useDataStore())
const props = defineProps({
  isOpen: Boolean,
  title: String,
  device_code: String | null
})

const satConfigParams = ref({
  fields: 'S16',
  measurement: 'SATSTAT',
})
const progs = ref([])

onBeforeUpdate( async () => {
  progs.value = []
  satConfigParams.value.device_code = props.device_code
  console.log(satConfigParams.value)
  await dataStore.getLastSatConfig(satConfigParams.value)
  fillSatData()
})

const postData = ref({
  command: 'SATSTAT',
  payload: {}
})


function onToggle(index) {
  console.log(index)
  console.log(progs.value)
  postData.value.payload = {}

  progs.value[index - 1]['prog' + index] = !progs.value[index - 1]['prog' + index]

  if (progs.value[index - 1]['prog' + index] === true) {
    postData.value.payload.S997 = String(index)
  } else if (progs.value[index - 1]['prog' + index] === false) {
    postData.value.payload.S999 = String(index)
  }
  console.log(postData.value.payload)
  console.log(props)
  dataStore.postControl(props.device_code, postData.value)
}

function fillSatData() {
  // let tmpS16 = '1,2,3,4'
  let tmpS16 = dataStore.satConfig === undefined ? undefined : dataStore.satConfig.S16

  if (tmpS16 === undefined) {
    for (let index = 0; index < 30; index++) {
      let progFalse = { ['prog' + (index + 1)]: false }
      progs.value.push(progFalse)
    }
  } else {
    // let splitS16 = tmpS16.split('/')
    let tmpProg = tmpS16.split(',')
    // let tmpProg = splitS16[0].split(',')
    // let tmpStat = splitS16[1].split(',')
    progs.value = []
    for (let index = 0; index < 30; index++) {
      if (tmpProg.includes(String((index + 1)))) {
        let progTrue = { ['prog' + (index + 1)]: true }
        progs.value.push(progTrue)
        // progs.value[index]['prog' + (index+1)] = true 
      } else {
        let progFalse = { ['prog' + (index + 1)]: false }
        progs.value.push(progFalse)
      }
    }
    console.log('here', progs.value)
  }
}

// Define custom events
const emits = defineEmits(['close',])

const target = ref(null)

function isMobileDevice() {
  return /Mobi|Android/i.test(navigator.userAgent);
}

onClickOutside(target, () => {
  if (!isMobileDevice()) {
    if (props.isOpen) {
      emits('close')
    }
  }
})


</script>

<style scoped>
.title {
  @apply text-left border-b-[1px] pb-[18px] sm:text-2xl text-base font-normal text-[#353535] mb-6
}


.message-wrapper {
  @apply flex items-center gap-2
}

.form-wrapper {
  @apply flex flex-col gap-6 text-[#353535]
}


.modal {
  @apply bg-[#ABADAF]/20 backdrop-blur-sm w-full h-full fixed top-0 left-0 px-8 pt-10 pb-4 overflow-x-hidden overflow-y-auto z-50 flex
}

.modal-inner {
  background: white;
  @apply rounded-[40px] border-2 border-[#005CB4] max-w-[650px] w-full h-fit p-10 m-auto
}

/* .modal-content {
  @apply 
    relative w-[600px] p-[40px] bg-white gap-[30px] rounded-xl
} */

input[type=text], input[type=password], input[type=number] {
  @apply pl-2 rounded py-2 w-full min-w-[30px]
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.select-option {
  @apply px-3 py-2 rounded-lg border bg-white text-[14px]
}

.field-wrapper {
  @apply flex gap-4 py-3 items-center
}

.button-wrapper {
  @apply mt-3 flex mx-auto w-full min-w-[40px]
}


.prog-wrapper {
  @apply flex flex-wrap gap-x-10 gap-y-2 justify-center
}

.toggle-wrapper {
  @apply flex flex-col items-center gap-4 px-5 py-3 w-20
}
</style>