<template>
  <loading :loading="isLoading" />
  <sidebar :noSocial="true" :backOn="true" />
  <DetailProgram :isOpen="openDetailProgram" :title="$t('detailPlanning')" @close="detailProgram"
    :program_code="selectPlanning" :device-code="devicesStore.deviceData.code" :start-date="startDate"
    :end-date="endDate" />
  <ManagePlanning :isOpen="openManagePlanning" :title="$t('programming')" @close="managePlanning"
    :device-code="devicesStore.deviceData.code" :schedule-at="startDate" :end-date="endDate" />
  <div class="device-container">
    <deviceCard :small=true :content="newData" />
    <div class="xs-icon-card">
      <img src="@/assets/calender.png">
      <p>{{ $t('consortiumWater') }}</p>
    </div>
  </div>
  <div class="dashboard-container">
    <IdroTitle :title="title" />
    <div class="m-auto">
      <div class="w-fit flex flex-col">
        <div class="w-fit">
          <VDatePicker v-model="selectedDate" :rows="3" :columns="4" @update:modelValue="handleDateChange"
            :attributes='attrs' is-required />
        </div>
        <div class="flex item w-full justify-between">
          <div class="flex items-start">
            <button v-for="item in program" :key="item" type="button"
              class="py-2.5 px-5 me-2 mt-2 font-bold border rounded-lg text-md hover:bg-gray-100"
              @click="detailProgram(item.program_code)"
              :class="item.state === 'QUEUE' ? 'border-orange-500 text-orange-500' : item.state === 'FAILED' ? 'border-red-500 text-red-500' : 'border-green-500 text-green-500'">
              {{ item.program_name }}
            </button>
            <button v-if="program.length === 0" type="button" disabled
              class="py-2.5 px-5 me-2 mt-2 font-bold border border-gray-300 rounded-lg text-md"
              style="align-self: center">
              No Program
            </button>
          </div>
          <button type="button"
            class="py-2.5 px-5 me-2 mt-2 border border-gray-300 rounded-lg hover:bg-gray-100 align-center"
            @click="managePlanning()">
            <img src="@/assets/edit-calendar.png">
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useDevicesStore } from '@/stores/DevicesStore'
import { useScheduleStore } from '@/stores/ScheduleStore';
import DetailProgram from '@/components/modal/consortiumWater/DetailProgram.vue'
import ManagePlanning from '@/components/modal/consortiumWater/ManagePlanning.vue';
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, computed, onMounted, ref, watch } from '@vue/runtime-core'

//props
const props = defineProps({
  id: String
})

const title = ref()
const selectedDate = new Date(new Date().getFullYear(), 0, 1)
const openDetailProgram = ref(false)
const openManagePlanning = ref(false)
//asynchronus component
const deviceCard = defineAsyncComponent(
  () => import('@/components/cards/deviceCard.vue'),
)

const attrs = ref([]);
const program = ref([]);
const selectPlanning = ref(0);
const startDate = ref('');
const endDate = ref('');

//state
const devicesStore = useDevicesStore()
const scheduleStore = useScheduleStore()
const { isLoading } = storeToRefs(useScheduleStore())
const { scheduleList, scheduleSpecificList } = storeToRefs(useScheduleStore())
const newData = computed(() => {
  return [devicesStore.deviceData]
})

function getTrailingNumber(str) {
  const match = str.match(/\d+$/);
  return match ? parseInt(match[0], 10) : null;
}

async function handleDateChange(newDate) {
  program.value = []

  const fromDate = new Date(newDate);
  fromDate.setUTCHours(0, 1, 0, 0);
  fromDate.setUTCDate(fromDate.getUTCDate() + 1)
  const fromFormattedDate = fromDate.toISOString()
  startDate.value = fromFormattedDate

  const fromFilterSpecific = new Date(fromFormattedDate)
  fromFilterSpecific.setHours(fromFilterSpecific.getHours() - 1)
  const fromFilterIso = fromFilterSpecific.toISOString()

  const toDate = new Date(newDate);
  toDate.setUTCDate(toDate.getUTCDate() + 2);
  toDate.setUTCHours(0, 0, 0, 0);
  const toFormattedDate = toDate.toISOString();
  endDate.value = toFormattedDate

  const toFilterSpecific = new Date(toFormattedDate)
  toFilterSpecific.setHours(toFilterSpecific.getHours() - 1)
  const toFilterIso = toFilterSpecific.toISOString()

  await scheduleStore.getSpecificSchedule(fromFilterIso, toFilterIso, devicesStore.deviceData.code)

  scheduleSpecificList.value.map((item) => {
    program.value.push({
      program_code: getTrailingNumber(item.payload_key),
      program_name: `Program ${getTrailingNumber(item.payload_key)}`,
      payload_key: item.payload_key,
      payload_value: item.payload_value,
      state: item.state,
      schedule_at: item.schedule_at,
      created_at: item.created_at,
      updated_at: item.updated_at,
    })
  })
}

  watch(() => openManagePlanning.value, async () => {
    if(openManagePlanning.value === false) {
      program.value = []

      scheduleSpecificList.value.map((item) => {
        program.value.push({
          program_code: getTrailingNumber(item.payload_key),
          program_name: `Program ${getTrailingNumber(item.payload_key)}`,
          payload_key: item.payload_key,
          payload_value: item.payload_value,
          state: item.state,
          schedule_at: item.schedule_at,
          created_at: item.created_at,
          updated_at: item.updated_at,
        })
      })

      await scheduleStore.getListSchedule(devicesStore.deviceData.code)
      attrs.value = []
      scheduleList.value.map((item) => {
        attrs.value.push({
          key: item.payload_key,
          highlight: 'green',
          dates: new Date(item.schedule_at),
        })
      })
    }
  })


onMounted(async () => {
  attrs.value = []
  await devicesStore.loadDevice(props.id)
  await scheduleStore.getListSchedule(devicesStore.deviceData.code)

    scheduleList.value.map((item) => {
      attrs.value.push({
        key: item.payload_key,
        highlight: 'green',
        dates: new Date(item.schedule_at),
      })
    })

    const fromDate = new Date(new Date().getFullYear(), 0, 1)
    fromDate.setUTCHours(0, 1, 0, 0);
    fromDate.setUTCDate(fromDate.getUTCDate() + 1)
    const fromFormattedDate = fromDate.toISOString();
    startDate.value = fromFormattedDate

    const fromFilterSpecific = new Date(fromFormattedDate)
    fromFilterSpecific.setHours(fromFilterSpecific.getHours() - 1)
    const fromFilterIso = fromFilterSpecific.toISOString()

    const toDate = new Date(new Date().getFullYear(), 0, 1)
    toDate.setUTCDate(toDate.getUTCDate() + 2);
    toDate.setUTCHours(0, 0, 0, 0);
    const toFormattedDate = toDate.toISOString();
    endDate.value = toFormattedDate;

    const toFilterSpecific = new Date(toFormattedDate)
    toFilterSpecific.setHours(toFilterSpecific.getHours() - 1)
    const toFilterIso = toFilterSpecific.toISOString()

    await scheduleStore.getSpecificSchedule(fromFilterIso, toFilterIso, devicesStore.deviceData.code)

    scheduleSpecificList.value.map((item) => {
      program.value.push({
        program_code: getTrailingNumber(item.payload_key),
        program_name: `Program ${getTrailingNumber(item.payload_key)}`,
        payload_key: item.payload_key,
        payload_value: item.payload_value,
        state: item.state,
        schedule_at: item.schedule_at,
        created_at: item.created_at,
        updated_at: item.updated_at,
      })
    })
    
    title.value = 'Idrosat:' + devicesStore.deviceData.name
  })

function detailProgram(program_code) {
  selectPlanning.value = program_code
  openDetailProgram.value = !openDetailProgram.value
}

function managePlanning() {
  openManagePlanning.value = !openManagePlanning.value
}


</script>

<style scoped>
.calendar {
  width: 1000px;
}

.dashboard-container {
  @apply relative flex flex-col px-[16px] md:px-[200px] lg:px-[260px] xl:px-[320px] 2xl:px-[360px];
}

.device-container {
  @apply flex sm:flex-col fixed items-end sm:items-center gap-2 bottom-0 left-4 pb-4 sm:pb-8
}

.content {
  @apply flex justify-center items-center gap-[20px] lg:gap-[30px] xl:gap-[40px] 2xl:gap-[80px] transition-all ease-in-out duration-300
}

.calendar-container {
  @apply bg-white p-4 rounded-lg border
}

.row {
  @apply flex flex-col relative justify-center gap-[60px] md:gap-[20px] lg:gap-[40px] xl:gap-[80px] transition-all ease-in-out duration-300
}


span h1 {
  @apply sm:text-3xl text-xl text-[#353535] font-medium
}

span h2 {
  @apply text-xs font-light
}

span p {
  @apply text-base font-normal
}
</style>