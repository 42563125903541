<template>
  <div>
     <transition name="fade">
       <div class="modal" v-show="isOpen" >
        <transition name="drop-in">
          <div class="modal-inner" v-show="isOpen" ref="target" >
            <div class="modal-content">
              <h1 class="title">{{title}}</h1>          
                
            </div>
            <div class="field-wrapper flex-col sm:flex-row">
              <span class="lg:w-[210px] md:w-[210px] sm:w-full font-semibold text-start">
                <label for="radiazione-solare">{{$t('stationsNumber')}} :</label>
              </span>
              <span class="flex w-full gap-2 items-center">
                <input 
                  class="w-full border outline-none " disabled :value="stationNumber" type="number" name="radiazione-solare" />
              </span>
            </div>
            <div class="field-wrapper flex-col sm:flex-row">
              <span class="lg:w-[270px] md:w-[270px] sm:w-full font-semibold">
                <label for="radiazione-solare">{{$t('irrigationTime')}} :</label>
              </span>
              <span class="flex w-full gap-2 items-center">
                <div class="block lg:flex md:flex sm:block">
                  <input 
                    class="w-full border outline-none " type="number" name="radiazione-solare" v-model="satData.ore"
                    >
                  <p class="w-full self-center">ore</p>
                </div>
                <div class="block lg:flex md:flex sm:block">
                  <input 
                    class="w-full border outline-none " type="number" name="radiazione-solare" v-model="satData.min"
                    >
                  <p class="w-full self-center">min</p>
                </div>
                <div class="block lg:flex md:flex sm:block">
                  <input 
                    class="w-full border outline-none " type="number" name="radiazione-solare" v-model="satData.sec"
                    >
                  <p class="w-full self-center">sec</p>
                </div>
              </span>
                <div class="w-full lg:w-[80px] md:w-[80px] sm:w-full">
                  <MyButton
                    @click="sendS996"
                    class="filled__blue" label="Start" 
                    :loading="postControlIsLoading"
                    />
                </div>
            </div>
            <div class="field-wrapper flex-col sm:flex-row">
              <span class="lg:w-[260px] md:w-[260px] sm:w-full font-semibold">
                <label for="radiazione-solare">{{$t('stopStation')}} N :</label>
              </span>
              <span class="flex w-full gap-2 items-center">
                <input 
                  class="w-full border outline-none " type="number" name="radiazione-solare" v-model="satData.ferma"/>
              </span>
              <div class="w-full lg:w-[80px] md:w-[80px] sm:w-full">
                <MyButton
                  @click="sedS998(1)"
                  class="filled__blue" label="Stop"
                  :loading="postControlIsLoading"
                />
              </div>
            </div>

            <div class="button-wrapper">
              <BaseButton type="button" class="filled__red mt-6"  :label="$t('cancel')" @click="emits('close')"/>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

  
<script setup>
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import BaseButton from '@/components/button/BaseButton.vue'
import { onClickOutside } from '@vueuse/core'
import MyButton from '@/components/button/BaseButton.vue'
import { useDataStore } from '@/stores/DataStore';
import { useDevicesStore } from '@/stores/DevicesStore'
import { useI18n } from 'vue-i18n';
  
  const { t } = useI18n();

  const dataStore = useDataStore()
  const { postControlIsLoading } = storeToRefs(useDataStore())
  const props = defineProps({
      isOpen: Boolean,
      title: String,
      stationNumber: Number,
      device_code: String | null
  })

  const postData = ref({
    command: 'SATSTAT',
    payload: {}
  })

  function sendS996() {
    postData.value.payload = {}
    let tmpStazione = props.stationNumber
    let tmpOre = satData.value.ore
    let tmpMin = satData.value.min
    let tmpSec = satData.value.sec
    let appendedValue = String(tmpStazione + ',' + tmpOre + ',' + tmpMin + ',' + tmpSec)
    postData.value.payload.S996 = appendedValue
    console.log(postData.value)
    dataStore.postControl(props.device_code,postData.value)
  }

  function sedS998(index) {
    postData.value.payload = {}
    if (index === 1) {
      satData.value.ferma
      postData.value.payload.S998 = satData.value.ferma
      console.log(postData.value.payload)
      dataStore.postControl(props.device_code,postData.value)
    } else if (index === 2) {
      satData.value.salta
      postData.value.payload.S998 = satData.value.salta
      console.log(postData.value.payload)
      dataStore.postControl(props.device_code,postData.value)
    }
  }

  const satData = ref({
    ore: 0,
    min: 0,
    sec: 0,
    ferma: 0,
  })

  // Define custom events
  const emits = defineEmits(['close',])
  
  const target = ref(null)

  function isMobileDevice() {
    return /Mobi|Android/i.test(navigator.userAgent);
  }

  onClickOutside(target, () => {
    if (!isMobileDevice()) {
      if (props.isOpen) {
        emits('close')
      }
    }
  })


</script>
  
  <style scoped>

  .title {
    @apply 
      text-left border-b-[1px] pb-[18px] 
      sm:text-2xl text-base font-normal text-[#353535] mb-6
  }
  

  .message-wrapper {
    @apply flex items-center gap-2
  }
  
  .form-wrapper{
    @apply flex flex-col gap-6 text-[#353535]
  }

  
.modal {
  @apply 
    bg-[#ABADAF]/20 backdrop-blur-sm
    w-full h-full
    fixed top-0 left-0 px-8 pt-10 pb-4
    overflow-x-hidden overflow-y-auto z-50
    flex 
}

.modal-inner {
  background: white;
  @apply 
  rounded-[40px] border-2 border-[#005CB4] max-w-[650px] w-full h-fit p-10 m-auto
}

/* .modal-content {
  @apply 
    relative w-[600px] p-[40px] bg-white gap-[30px] rounded-xl
} */

input[type=text], input[type=password], input[type=number] {
  @apply pl-2 rounded py-2 w-full min-w-[30px]
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.select-option {
  @apply  px-3 py-2
          rounded-lg border bg-white
          text-[14px]
} 

.field-wrapper {
  @apply  flex gap-4
          py-3 items-center
}

.button-wrapper {
  @apply mt-3 flex mx-auto w-full min-w-[40px]
}

</style>