<template>
  <div class="slider"></div>
  <loading :loading="loadingData" />
  <!-- <impiantoAddModal 
    v-if="isShowAddModal" 
    @close="toggleAddModal" 
    title="AGGIUNGI IMPIANTO" 
    /> -->
  <NewDevice :isOpen="isShowAddModal" @close="toggleAddModal" :title="$t('createPlant')" />
  <impiantoEditModal v-if="isShowEditModal" @close="toggleEditModal" :title="$t('editPlant')" />
  <impiantoDelModal v-if="isShowDelModal" @close="toggleDelModal" :title="$t('deletePlant')" />
  <toolBar :noAdd=false @addClick="toggleAddModal" @editClick="toggleEditModal" @deleteClick="toggleDelModal" />
  <sidebar :noSocial="true" :backOn="true" />
  <div class="dashboard-container">
    <div class="flex">
      <IdroTitle :title="$t('welcome')" :name="username" />
    </div>
    <div class="flex justify-center">
      <div class="flex input-wrapper mb-8">
        <span class="self-center">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
            <path
              d="M23.707,22.293l-5.969-5.969a10.016,10.016,0,1,0-1.414,1.414l5.969,5.969a1,1,0,0,0,1.414-1.414ZM10,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,10,18Z," />
          </svg>
        </span>
        <input class="ml-3 self=center w-full" v-model="searchValue" @input="searchEntries"
          placeholder="Search device by name or serial number">
      </div>
    </div>
    <deviceCard :content="dataDevice" :clickable=true :grid=true :medium=true @clicked="gotoDeviceConfig" />
  </div>
</template>

<script setup>
import SearchBar from '@/components/SearchBar.vue';
import { useDevicesStore } from '@/stores/DevicesStore'
import { storeToRefs } from 'pinia'
import { defineAsyncComponent, onBeforeMount, onMounted, onUnmounted, ref } from '@vue/runtime-core'
import router from '@/router'
import NewDevice from '@/components/modal/devices/NewDevice'
import { useDeviceManagement } from '@/stores/DeviceManagementStore'

const deviceManagementStore = useDeviceManagement()

onMounted(() => {
  deviceManagementStore.superAdminDevices()
})
//asynchronus component
  const deviceCard = defineAsyncComponent(
    () => import('@/components/cards/deviceCard.vue'),
  )
const impiantoAddModal = defineAsyncComponent(() =>
  import('@/components/popups/impiantoAddModal.vue')
)
const impiantoDelModal = defineAsyncComponent(() =>
  import('@/components/popups/impiantoDelModal.vue')
)
const impiantoEditModal = defineAsyncComponent(() =>
  import('@/components/popups/impiantoEditModal.vue')
)
//init store
const devicesStore = useDevicesStore()
const { devicesList } = storeToRefs(useDevicesStore())
const username = localStorage.getItem('auth.user')
//function
const isShowAddModal = ref(false)
const isShowDelModal = ref(false)
const isShowEditModal = ref(false)
const dataDevice = ref([])
const searchValue = ref('')

function searchEntries() {
  dataDevice.value = devicesList.value.filter((data) => data.name.toLocaleLowerCase().match(searchValue.value.toLocaleLowerCase() || data.code.toLocaleLowerCase().match(searchValue.value.toLocaleLowerCase())))
  if (searchValue.value === '') {
    dataDevice.value = devicesList.value
  }
}

function toggleAddModal() {
  isShowAddModal.value = !isShowAddModal.value
}
function toggleDelModal() {
  isShowDelModal.value = !isShowDelModal.value
}
function toggleEditModal() {
  isShowEditModal.value = !isShowEditModal.value
}

const gotoDeviceConfig = (id) => {
  router.push({ name: 'DeviceConfig', params: { id: id } })
}

const gotoAddDevice = () => {
  router.push({ name: 'AddDevice' })
}

async function getLastData() {
  await devicesStore.loadDevices()
  dataDevice.value = devicesList.value
}

let getLastDataInterval = null
const loadingData = ref(false)

//fetch data
onBeforeMount(async () => {
  loadingData.value = true
  await getLastData()
  loadingData.value = false
  getLastDataInterval = setInterval(getLastData, 10000)
})

onUnmounted(() => {
  clearInterval(getLastDataInterval)
})
</script>

<style scoped>
.dashboard-container {
  @apply flex flex-col w-full
}

.input-wrapper {
  @apply text-left px-3 py-2 rounded-lg border border-[#005CB4] bg-transparent w-6/12
}

input {
  @apply outline-none text-[12px] sm:text-base
}

span h1 {
  @apply sm:text-3xl text-xl text-[#353535] font-medium
}

span h2 {
  @apply text-xs font-light
}

span p {
  @apply text-base font-normal
}

/* .impiantos {
  @apply  flex flex-wrap justify-between gap-8
          w-[1000px] mx-auto
}

.impiantos modal {
  @apply w-72 transition ease-in-out delay-150 hover:-translate-y-2
} */
</style>