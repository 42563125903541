<template>
  <div>
    <alert :message="status.message" :modalActive="modalActive" :isError="status.isError" @close="closeNotification" />
    <transition name="fade">
      <div class="modal" v-show="isOpen">
        <transition name="drop-in">
          <div class="modal-inner" v-show="isOpen" ref="target">
            <div class="modal-content">
              <h1 class="title">{{ title }}</h1>
              <div class="flex">
                <button v-for="(tab, index) in tabs" :key="index" @click="activeTab = index" :class="{
                  'border-white text-[#353535]': activeTab === index,
                  'border-transparent text-gray-300': activeTab !== index
                }" class="px-4 py-2 font-semibold border-b-2 transition duration-300 ease-in-out">
                  {{ tab }}
                </button>
              </div>

              <!-- Tabs Content -->
              <div class="p-4">
                <div :style="{ display: activeTab === 0 ? 'block' : 'none' }">
                  <VeeForm :validation-schema="schema" v-slot="{ handleSubmit }" as="div" ref="form">
                    <form @submit="handleSubmit($event, onSubmit)" class="form-wrapper">
                      <BaseInput name="code" type="text" placeholder="Device ID" class="white" label="Device ID" />
                      <BaseInput name="name" type="text" :placeholder="$t('name')" class="white" :label="$t('name')" />
                      <div class="flex w-full items-center gap-2">
                        <BaseInput name="password" :type="password ? 'text' : 'password'" placeholder="Device Password"
                          class="white w-full" label="Password" />
                        <input class="cursor-pointer hidden w-[30px]" id="show-password" type="checkbox"
                          v-model="password"><label class="cursor-pointer" for="show-password">
                          <img v-if="!password" alt="aid logo" class="w-[24px]" src="../../../assets/eye-open.svg"
                            width="200" height="200" />
                          <img v-if="password" alt="aid logo" class="w-[24px]" src="../../../assets/eye-close.svg"
                            width="200" height="200" />
                        </label>
                      </div>
                      <BaseInput name="type" type="text" placeholder="Type" class="white" label="Type" />
                      <!-- <BaseInput name="max_devices" type="number" placeholder="Max devices" class="white" label="Max Devices"/> -->

                      <div class="bg-white rounded-lg text-left px-4 py-2 flex flex-col gap-1 border">
                        <span class="font-bold">GPS Location</span>
                        <input v-model="coordinate" class="outline-none" type="text" name="coordinate"
                          placeholder="Coordinate" required>
                      </div>
                      <div class="w-fit">
                        <BaseButton type="button" class="outlined___blue" :label="t('getLocation')" @click="getLocation()"
                          :loading="getLocationLoading" />
                      </div>
                      <div class="sm:flex-row flex-col flex justify-between gap-6 sm:gap-10">
                        <BaseButton type="button" class="outlined___blue" :label="cancelLabel" @click="cancelForm" />
                        <BaseButton type="submit" class="filled__blue" :label="registerLabel"
                          :loading="createDeviceIsLoading" />
                      </div>
                    </form>
                  </VeeForm>
                </div>
                <div :style="{ display: activeTab === 1 ? 'block' : 'none' }">
                  <VeeForm :validation-schema="schemaQr" v-slot="{ handleSubmit }" as="div" ref="formQrCode">
                    <form @submit="handleSubmit($event, onSubmitQrCode)" class="form-wrapper">
                      <div class="bg-white rounded-lg p-2 text-left flex flex-col gap-1">
                        <span class="font-bold p-1">Sqan Qr</span>
                        <QrcodeStream @detect="onDetect" />
                        <span :class="{
                          'text-red-500 font-semibold mt-1 text-center': validateQr === 'error',
                        }" :style="{ display: validateQr === '' ? 'none' : 'block' }">
                          {{ validateQr === 'error' ? 'This QR is not valid' : '' }}
                        </span>
                      </div>
                      <div class="bg-white rounded-lg text-left px-4 py-2 flex flex-col gap-1 border">
                        <span class="font-bold">Device Id</span>
                        <input v-model="deviceCode" class="outline-none" type="text" name="code" placeholder="Device ID"
                          disabled>
                      </div>
                      <BaseInput name="nameWithQr" type="text" :placeholder="$t('name')" class="white" :label="$t('name')" />

                      <div class="flex w-full items-center gap-2">
                        <div class="bg-white rounded-lg text-left px-4 py-2 flex flex-col gap-1 border w-full">
                          <span class="font-bold">Password</span>
                          <input :type="password ? 'text' : 'password'" v-model="devicePass" class="outline-none" type="password" name="password"
                            placeholder="Password" disabled>
                        </div>
                        <input class="cursor-pointer hidden w-[30px]" id="show-password-with-qr" type="checkbox"
                          v-model="password"><label class="cursor-pointer" for="show-password-with-qr">
                          <img v-if="!password" alt="aid logo" class="w-[24px]" src="../../../assets/eye-open.svg"
                            width="200" height="200" />
                          <img v-if="password" alt="aid logo" class="w-[24px]" src="../../../assets/eye-close.svg"
                            width="200" height="200" />
                        </label>
                      </div>

                      <BaseInput name="typeWithQr" type="text" placeholder="Type" class="white" label="Type" />
                      <!-- <BaseInput name="max_devices" type="number" placeholder="Max devices" class="white" label="Max Devices"/> -->

                      <div class="bg-white rounded-lg text-left px-4 py-2 flex flex-col gap-1 border">
                        <span class="font-bold">GPS Location</span>
                        <input v-model="coordinate" class="outline-none" type="text" name="coordinate"
                          placeholder="Coordinate" required>
                      </div>
                      <div class="w-fit">
                        <BaseButton type="button" class="outlined___blue" :label="t('getLocation')" @click="getLocation()"
                          :loading="getLocationLoading" />
                      </div>
                      <div class="sm:flex-row flex-col flex justify-between gap-6 sm:gap-10">
                        <BaseButton type="button" class="outlined___blue" :label="cancelLabel" @click="cancelForm" />
                        <BaseButton type="submit" class="filled__blue" :label="registerLabel"
                          :loading="createDeviceIsLoading" />
                      </div>
                    </form>
                  </VeeForm>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>


<script>
export default {
  data() {
    return {
      tabs: ['Manual', 'With Qr Code'], // Nama tab
      activeTab: 0 // Tab aktif
    };
  },
};
</script>


<script setup>
import BaseInput from '@/components/input/NewInput.vue'
import BaseButton from '@/components/button/BaseButton.vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import { Form as VeeForm } from 'vee-validate'
import { storeToRefs } from 'pinia'
import { ref, onMounted } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { addDeviceSchema } from '@/composable/devicesSchema'
import { addDeviceSchemaWithQr } from '@/composable/devicesSchema'
import { useDevicesStore } from '@/stores/DevicesStore'
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
  isOpen: Boolean,
  title: String
})

const latitude = ref('')
const longitude = ref('')
const coordinate = ref('')
const deviceCode = ref('')
const devicePass = ref('')
const validateQr = ref('')
const getLocationLoading = ref(false)
const password = ref(false)

function getLocation() {
  getLocationLoading.value = true
  navigator.geolocation.getCurrentPosition(showPosition);
}

function onDetect(detectedCodes) {
  const rawValue = detectedCodes[0].rawValue;
  const splitValues = rawValue.split(' ');

  if (splitValues[1]) {
    deviceCode.value = splitValues[0]
    devicePass.value = splitValues[1]

    validateQr.value = 'success'
  } else {
    validateQr.value = 'error'
  }
}

function showPosition(position) {
  coordinate.value = position.coords.latitude.toString() + ',' + position.coords.longitude.toString()
  latitude.value = position.coords.latitude
  longitude.value = position.coords.longitude
  console.log(coordinate.value)
  getLocationLoading.value = false
}
const schema = addDeviceSchema
const schemaQr = addDeviceSchemaWithQr
const modalActive = ref(false)
const devicesStore = useDevicesStore()
const { status, createDeviceIsLoading } = storeToRefs(useDevicesStore())
const cancelLabel = ref(t('cancel'))
const registerLabel = ref(t('save'))
const regButtonClick = ref(0)
const cancelButtonClick = ref(0)

onMounted(() => {
  // devicesStore.loadDevices()
})

const onSubmitQrCode = async (values, { resetForm }) => {
  values.company = 'idrolife'
  values.coordinate = coordinate.value
  values.code = deviceCode.value
  values.password = devicePass.value

  const modifiedValues = {
    ...values,
    name: values.nameWithQr, 
    type: values.typeWithQr 
  };

  delete modifiedValues.nameWithQr;
  delete modifiedValues.typeWithQr;

  regButtonClick.value = ++regButtonClick.value
  if (regButtonClick.value == 1) {
    registerLabel.value = t('dataCorrect')
  }

  if (regButtonClick.value == 2) {
    await devicesStore.createDevices(modifiedValues)
    modalActive.value = true
    if (status.value.isError == true) {
      setTimeout(closeNotification, 3000)
    } else {
      setTimeout(closeNotification, 3000)
      resetForm()
    }
    registerLabel.value = t('save')
    regButtonClick.value = 0
    devicesStore.loadDevices()
  }
}

const onSubmit = async (values, { resetForm }) => {
  values.company = 'idrolife'
  values.coordinate = coordinate.value
  regButtonClick.value = ++regButtonClick.value
  if (regButtonClick.value == 1) {
    registerLabel.value = t('dataCorrect')
  }

  if (regButtonClick.value == 2) {
    await devicesStore.createDevices(values)
    modalActive.value = true
    if (status.value.isError == true) {
      setTimeout(closeNotification, 3000)
    } else {
      setTimeout(closeNotification, 3000)
      resetForm()
    }
    registerLabel.value = t('save')
    regButtonClick.value = 0
    devicesStore.loadDevices()
  }
}

const closeNotification = () => {
  modalActive.value = false
}
// Define custom events
const emits = defineEmits(['close'])

const form = ref(null)
const formQrCode = ref(null)
const target = ref(null)

onClickOutside(target, () => {
  cancelButtonClick.value = 0
  regButtonClick.value = 0
  registerLabel.value = t('save')
  cancelLabel.value = t('cancel')
  if (props.isOpen) {
    emits('close')
  }
})

function cancelForm() {
  cancelButtonClick.value = ++cancelButtonClick.value
  switch (cancelButtonClick.value) {
    case 1:
      cancelLabel.value = t('dataLost')
      break;
    case 2:
      form.value.resetForm()
      formQrCode.value.resetForm()
      deviceCode.value = ''
      devicePass.value = ''
      coordinate.value = ''
      emits('close')
      cancelButtonClick.value = 0
      cancelLabel.value = t('cancel')
      break;
  }
}



</script>

<style scoped>
.title {
  @apply text-left border-b-[1px] pb-[18px] sm:text-2xl text-base font-normal text-[#353535] mb-6
}


.message-wrapper {
  @apply flex items-center gap-2
}

.form-wrapper {
  @apply flex flex-col gap-6
}


.modal {
  @apply bg-[#ABADAF]/20 backdrop-blur-sm w-full h-full fixed top-0 left-0 px-8 pt-10 pb-4 overflow-x-hidden overflow-y-auto z-40 flex
}

.modal-inner {
  background: white;
  @apply rounded-[40px] border-2 border-[#005CB4] max-w-[500px] w-full h-fit p-10 m-auto
}

/* .modal-content {
  @apply 
    relative w-[600px] p-[40px] bg-white gap-[30px] rounded-xl
} */

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.drop-in-enter-active,
.drop-in-leave-active {
  transition: all 0.3s ease-out;
}

.drop-in-enter-from,
.drop-in-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.select-option {
  @apply px-3 py-2 rounded-lg border bg-white text-[14px]
}
</style>