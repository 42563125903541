import { defineStore } from 'pinia'
import devicesApi from '@/services/deviceAPI'
import { ref } from 'vue'
import { useDataStore } from './DataStore'

export const useDevicesStore = defineStore('impianto', () => {
  const deviceGeo = ref({})
  const deviceRhData = ref([])
  const loadDeviceGeoIsLoading = ref(false)
  const loadDeviceGeoStatus = ref({
    message: null,
    code: null
  })
  const postDeviceGeoIsLoading = ref(false)
  const postDeviceGeoStatus = ref({
    message: null,
    code: null,
    isError: false
  })
  const devicesList = ref([])
  const deviceData = ref([])
  const createDeviceIsLoading = ref()
  const updateDeviceIsLoading = ref()
  const isLoading = ref(false)
  const loadDeviceIsLoading = ref(false)
  const status = ref({
    message: null,
    code: null,
    isError: null
  })
  const dataStore = useDataStore()
  const loadDevices = async () => {
    isLoading.value = true
    try {
      const res = await devicesApi.getDevices()
      devicesList.value = res.data.data.devices
      devicesList.value.map((data) => {
        data.status = data.status === 'ONLINE' ? true : false
        console.log(data.is_alarm_device)
        data.is_alarm_device = data.is_alarm_device > '0' ? true : false
        data.last_heard = new Date(data.last_heard).toLocaleString()
      })
      isLoading.value = false
    } catch (err) {
      console.error(err)
      isLoading.value = false
      return err
    }
  }

  const loadDevice = async (id) => {
    loadDeviceIsLoading.value = true
    try {
      const res = await devicesApi.getDevice(id)
      console.log(res)
      deviceData.value = res.data.data.device
      deviceData.value.status = deviceData.value.status === 'ONLINE' ? true : false
      deviceData.value.is_alarm_device = deviceData.value.is_alarm_device > '0' ? true : false
      deviceData.value.last_heard = new Date(deviceData.value.last_heard).toLocaleString()

      loadDeviceIsLoading.value = false
    } catch (err) {
      console.error(err)
      loadDeviceIsLoading.value = false
      return err
    }
  }

  const createDevices = async (deviceData) => {
    createDeviceIsLoading.value = true
    try {
      const res = await devicesApi.postDevices(deviceData)
      console.log(res)
      status.value.message = 'Device Created'
      status.value.code = res.data.status
      status.value.isError = false
      createDeviceIsLoading.value = false
    } catch (err) {
      console.error(err)
      createDeviceIsLoading.value = false
      status.value.message = err.response.data.error
      status.value.isError = true
      status.value.code = err.response.data.status
      return err
    }
  }

  const updateDevice = async (id, deviceData) => {
    updateDeviceIsLoading.value = true
    try {
      const res = await devicesApi.updateDevice(id, deviceData)
      console.log(res)
      status.value.message = 'Device Updated'
      status.value.code = res.data.status
      status.value.isError = false
      updateDeviceIsLoading.value = false
    } catch (err) {
      console.error(err)
      updateDeviceIsLoading.value = false
      status.value.message = err.response.data.error
      status.value.code = err.response.data.status
      status.value.isError = true
      return err
    }
  }

  const deleteDevice = async (id) => {
    isLoading.value = true
    try {
      const res = await devicesApi.deleteDevice(id)
      status.value.message = 'Device Deleted'
      status.value.code = res.data.status
      isLoading.value = false
      console.log(res)
    } catch (err) {
      console.error(err)
      isLoading.value = false
      return err
    }
  }

  const loadDeviceGeo = async (id) => {
    loadDeviceGeoIsLoading.value = true
    try {
      const res = await devicesApi.getDeviceGeo(id)
      loadDeviceGeoStatus.value.code = res.data.status
      loadDeviceGeoIsLoading.value = false
      deviceGeo.value = res.data.data.device.deviceGeos
    } catch (err) {
      console.error(err)
      loadDeviceGeoStatus.value.code = err.response.data.status
      loadDeviceGeoStatus.value.message = 'device not exist'
      loadDeviceGeoIsLoading.value = false
      return err
    }
  }

  const postDeviceGeo = async (data) => {
    postDeviceGeoIsLoading.value = true
    try {
      const res = await devicesApi.postDeviceGeo(data)
      postDeviceGeoStatus.value.code = res.data.status
      postDeviceGeoStatus.value.message = "Coordinate Updated"
      postDeviceGeoStatus.value.isError = postDeviceGeoStatus.value.code === 'fail' ? true : false
      postDeviceGeoIsLoading.value = false
      console.log(res)
    } catch (err) {
      console.error(err)
      postDeviceGeoStatus.value.code = err.response.data === undefined ? 'fail' : err.response.data.status
      postDeviceGeoStatus.value.isError = postDeviceGeoStatus.value.code === 'fail' ? true : false
      postDeviceGeoStatus.value.message = err.response.data === undefined ? err.message : String(err.response.data.error.errors[0].field + ',' + err.response.data.error.errors[0].message)
      postDeviceGeoIsLoading.value = false
      return err
    }
  }

  const getDeviceRh = async (deviceCode) => {
    try {
      const res = await devicesApi.getRhData(deviceCode)
      deviceRhData.value = res.data.data.rhs

    } catch (e) {
      console.error(e)
      return e
    }
  }

  const postDeviceRh = async (data) => {
    try {
      const res = await devicesApi.postRhData(data)

      return res.data
    } catch (e) {
      console.error(e)
      return e
    }
  }

  return {
    devicesList, isLoading, loadDevices, createDevices, deleteDevice, status, loadDevice, deviceData, createDeviceIsLoading,
    loadDeviceGeo, deviceGeo, loadDeviceGeoIsLoading, loadDeviceGeoStatus,
    postDeviceGeo, postDeviceGeoIsLoading, postDeviceGeoStatus,
    updateDeviceIsLoading, updateDevice, getDeviceRh, deviceRhData, postDeviceRh
  }
})