

import { defineStore } from 'pinia'
import scheduleApi from '@/services/scheduleAPI'
import { ref } from 'vue'

export const useScheduleStore = defineStore('schedule', {
  state: () => ({
    scheduleList: ref([]),
    scheduleSpecificList: ref([]),
    isLoading: ref(false),
    status: ref({
      message: null,
      code: null,
    }),
  }),
  actions: {
    async getListSchedule(deviceCode) {
      this.isLoading = true
      try {
        const res = await scheduleApi.getListSchedule(deviceCode)
        this.scheduleList = res.data.data.schedule 
        this.isLoading = false
      } catch (err) {
        console.error(err)
        this.isLoading = false
        return err
      }
    },
    async getSpecificSchedule(from, to, deviceCode) {
      this.isLoading = true
      try {
        const res = await scheduleApi.getSpecificSchedule(from, to, deviceCode)
        this.scheduleSpecificList = res.data.data.schedule
        this.isLoading = false
      } catch (err) {
        console.error(err)
        this.isLoading = false
        return err
      }
    },
    async createSchedule(data) {
      this.isLoading = true
      try {
        await scheduleApi.createSchedule(data)
        this.status.message = 'Schedule created!'
        this.status.isError = false
        this.isLoading = false
      } catch (err) {
        console.error(err)
        this.status.message = 'Error create schedule'
        this.status.isError = true
        this.isLoading = false
        return err
      }
    },
    async deleteSchedule(id) {
      this.isLoading = true
      try {
        await scheduleApi.deleteSchedule(id)
        this.status.message = 'Schedule deleted!'
        this.status.isError = false
        this.isLoading = false
      } catch (err) {
        console.error(err)
        this.status.message = 'Error delete schedule'
        this.status.isError = true
        this.isLoading = false
        return err
      }
    },
  }
})