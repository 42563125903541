import apiClient from "./API";

export default {
  getListSchedule(deviceCode){
    return apiClient.get(`device/schedule?device_code=${deviceCode}`)
  },
  getSpecificSchedule(from, to, deviceCode){
    return apiClient.get(`device/schedule?from=${from}&to=${to}&device_code=${deviceCode}`)
  },
  createSchedule(data){
    return apiClient.post('device/schedule', data)
  },
  deleteSchedule(id){
    return apiClient.delete(`device/schedule/${id}`)
  }
}