import { defineStore } from 'pinia'
import weatherApi from '@/services/weatherAPI'

export const useWeatherStore = defineStore('wheaterData', () => {
  const getCurrent = async (coordinate) => {
    try {
      const res = await weatherApi.weatherData(coordinate)

      return res.data
    }catch(e) {
      console.error(e)
      return e
    }
  }

  return {
    getCurrent
  }
})